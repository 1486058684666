// ------------------------drop down language---------------------

.margin-lang {
    margin: 10px 0px;
}

.lang_flag_regis {
    border-radius: 4px;
    width: 24px;
    height: auto;
}

.lang_flag {
    border-radius: 5px;
    width: 30px;
    height: auto;
}

.lang_flag_ak {
    border-radius: 5px;
    width: 35px;
    height: auto;
}

.caret_icon {
    font-size: 7px;
    padding-top: 7px;
    padding-left: 8px;
}

.lang_dropdown {
    border: 0;
    box-shadow: 0px 9px 40px rgba(0, 0, 0, 0.104977);
    border-radius: 5px;
    min-width: 100px;
}

.lang_dropdown li {
    padding: 10px;
}

.lang_dropdown .lang_active,
.lang_dropdown li:hover {
    background-color: linear-gradient(88deg,
            rgb(19, 180, 202),
            rgb(24, 202, 190)) !important;
}

.user_select,
.lang_select {
    color: #646464 !important;
    display: -webkit-inline-box;
    display: inline-flex;
    vertical-align: middle;
    cursor: pointer;
}

.eng,
.thai {
    cursor: pointer;
    vertical-align: middle;
}

.thai {
    border-bottom: 0.5px solid #f1f1f1;
}

.lang_text {
    height: 100%;
    font-size: 14px;
    padding-left: 15px;
}

.lang_text_regis {
    height: 100%;
    font-size: 10px;
    padding-left: 6px;
}

.set-dslide-ak {
    top: 27px !important;
    left: -64px !important;
}

// ------------------------drop down language---------------------

/************************************************ The check boxcontainer ***************************************/
.chcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.check {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background: #ffffff;
    border: 2px solid #ced2d5;
    border-radius: 3px;
}

.chcontainer:hover input~.check {
    background-color: #ccc;
}

.chcontainer input:checked~.check {
    background-color: #00c292;
    border: 1px solid #00c292;
    border-radius: 3px;
}

.check:after {
    content: "";
    position: absolute;
    display: none;
}

.chcontainer input:checked~.check:after {
    display: block;
}

.chcontainer .check:after {
    left: 8px;
    top: 1px;
    width: 8px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/****************************************************end of checkbox**************************************/

.required {
    &::after {
        margin-left: 1px;
        color: rgb(255, 0, 0);
        content: "*";
    }
}

