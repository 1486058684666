// Body
$body-bg: #f8fafc;

// Typography
// $font-family-sans-serif: 'Nunito', sans-serif;
$font-family-sans-serif: "Prompt", sans-serif;

* {
    font-family: $font-family-sans-serif;
}

//import font
@font-face {
    font-family: "Roboto-Thin";
    src: url("/fonts/font/Roboto-Thin.woff2") format("woff2"), url("/fonts/font/Roboto-Thin.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Roboto-Medium";
    src: url("/fonts/font/Roboto-Medium.woff2") format("woff2"), url("/fonts/font/Roboto-Medium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Roboto-Bold";
    src: url("/fonts/font/Roboto-Bold.woff2") format("woff2"), url("/fonts/font/Roboto-Bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

$font-size-base: 0.9rem;
$line-height-base: 1.6;
// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$deepYellow: #fbc02d;
$deepOrange: #e65100;
$deepBlue: #0d47a1;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);