.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #000;
    text-align: center;
    background-color: #fff;
    border-radius: 0.25rem;
  }
  .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
  .tooltip.bs-tooltip-top .arrow::before {
    margin-left: -3px;
    content: "";
    border-width: 5px 5px 0;
    border-top-color: #fff;
  }
  .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
  .tooltip.bs-tooltip-right .arrow::before {
    margin-top: -3px;
    content: "";
    border-width: 5px 5px 5px 0;
    border-right-color: #fff;
  }
  .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
  .tooltip.bs-tooltip-bottom .arrow::before {
    margin-left: -3px;
    content: "";
    border-width: 0 5px 5px;
    border-bottom-color: #fff;
  }
  .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
  .tooltip.bs-tooltip-left .arrow::before {
    right: 0;
    margin-top: -3px;
    content: "";
    border-width: 5px 0 5px 5px;
    border-left-color: #fff;
  }
