table {
    background: #ffffff !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    border-width: 0px !important;
}
table thead tr {
    border-bottom: 2px solid #617c36;
}
.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 0 !important;
}
.table-bordered th,
.table-bordered td {
    border: none;
}
.con_card {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: #ffffff;
    border: 2px solid #c5c5c5;
    border-radius: 10px;
    padding: 15px;
    word-break: break-all;
    height: 100%;
    .con_score {
        word-break: break-all;
        display: flex;
        flex-direction: row;
        gap: 11px;
    }
    .con_score span:first-child {
        font-weight: 600;
    }
}

.vs__actions {
    display: flex;
    align-items: center;
    padding: 0px 6px 0px 0px !important;
}

.vs--searchable .vs__dropdown-toggle {
    cursor: text;
    // border: none !important;
}

.vs__search,
.vs__search:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.4;
    font-size: 1em;
    border: 1px solid transparent;
    border-left: none;
    outline: none;
    margin: 0px 0 0 0 !important;
    padding: 0 7px;
    background: none;
    box-shadow: none;
    width: 0;
    max-width: 100%;
    flex-grow: 1;
    z-index: 1;
}

.vs__selected {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 4px;
    color: #333;
    line-height: 1.4;
    margin: 0px 0px 0px 0px !important;
    padding: 0 0.25em;
    z-index: 0;
}

.vs__dropdown-toggle {
    height: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    padding: 0;
    background: none;
    border: 0px solid rgba(60, 60, 60, 0.26);
    border-radius: 4px;
    white-space: normal;
}
.v-select {
    position: relative;
    font-family: inherit;
    order: none;
    padding: 8px 2px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    height: 40px;
}

.modal-content {
    border-radius: 1rem;
}
h1 {
    font-size: 1.8rem;
    padding: 15px 0.5rem;
}
.cursor_pointer {
    cursor: pointer !important;
}
.v-select,
.v-select * {
    cursor: pointer !important;
}
.btn-loc,
.btn-add {
    transition: all 0.2s;
    &:hover {
        background: #2f6369 !important;
        transform: scale(1.1);
    }
}
// .container-excel {
//     background: #62c483 !important;
//     padding: 6px 0 !important;
//     border-radius: 12px !important;
// }
