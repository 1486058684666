$btn_height : 40px;

// ---------------------circle crop area---------------------------
// .cropper-crop-box,
// .cropper-view-box {
//     border-radius: 50%;
// }

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}

.cropper-face {
    background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
    display: none !important;
}

.cropper-view-box {
    outline: inherit !important;
}

// ---------------------circle crop area---------------------------
.cropped_image_area,
.finished_cropped_area {
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    background: #EEEEEE;
}



/* -------------------------------- */
.button_holder {
    padding-left: 15px;
    padding-right: 15px;
}

.brower_holder {
    position: relative;
}

.image_browse {
    position: absolute;
    width: 10px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.fake_browse_btn {
    height: $btn_height;
    width: 100%;
    border: 0;
    background: $yellow;
    outline-style: none;
    color: $black;

    &:hover {
        background: $deepYellow;
        color: $white;
    }
}



.delete_cross_btn {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    font-size: 25px;
    color: #FF5A5F !important;
    opacity: .1;

    &:hover {
        opacity: 1;
    }
}

.back_to_crop_btn {
    height: $btn_height;
    width: 100%;
    border: 0;
    outline-style: none;
    color: #ffffff;
    background: $black;
}

.bg-deepOrange {
    background: $deepOrange;
}

.bg-deepBlue {
    background: $deepBlue;
}

.bg-black {
    background: $black;
}

/* ------------------------------------- */
.invisibleHolder {
    visibility: hidden;
}

.my_cal_block {
    position: relative;

    & input {
        border-radius: 4px;
        height: 37px;
        width: 100% !important;
    }

    .mx-datepicker {
        display: auto;
        width: 100%;
    }

    .mx-datepicker-range {
        width: 100% !important;
    }
}

.my_datepicker_popup {
    z-index: 5000;
    box-shadow: 0px 4px 20px rgba(131, 131, 131, 0.4);
    border-radius: 12px;
    padding: 20px;

    .mx-calendar-content .cell {
        cursor: pointer;
        color: #212b35;
    }

    .mx-calendar-content .cell:hover {
        color: #73879c;
        background-color: #ffede6;
    }

    .mx-calendar-content .cell.active {
        color: #fff;
        background-color: #ff672b;
        border-radius: 2px;
    }

    .mx-calendar-content .cell.in-range {
        color: #73879c;
        background-color: #ffede6;
    }

    .mx-calendar-content .cell.disabled {
        cursor: not-allowed;
        color: #ccc;
        background-color: #f3f3f3;
    }

    .mx-datepicker-footer {
        padding: 6px 8px;
        text-align: center;
        border-top: 0;
    }

    .mx-datepicker-btn-confirm {
        background: #3490dc;
        border-radius: 8px;
        color: #ffffff;
        min-width: 158px;
        min-height: 39px;
        font-size: 15px;
        margin-top: 13px;
        margin-bottom: -5px;

        &:hover {
            color: #ffffff;
        }
    }

    .mx-calendar.mx-calendar-panel-date {
        border: 0.675355px solid #dfe4e8;
        border-radius: 5.40284px;
    }

    .mx-table.mx-table-date tr th {
        border-top: 0.675355px solid #dfe4e8;
        border-bottom: 0.675355px solid #dfe4e8;
    }

    .mx-table-date .today {
        color: #ffffff;
        background-color: #3490dc;
        border-radius: 2px;
    }

    .mx-table.mx-table-date .mx-date-row td {
        border-top: 0.4px solid #ffffff;
        border-bottom: 0.4px solid #ffffff;
    }

    .mx-calendar-header-label button {
        color: #212b35;
        font-weight: bold;
    }
}
