// loader Style *******************
.loader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(192, 192, 192, 0.5);
    background-repeat: no-repeat;
    background-position: center;
}
// login background
.background_img {
    background-image: url("/img/bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

// Sidebar Style *******************

// margin sidebar and content
.main-sidebar,
.main-sidebar::before {
    width: 132px !important;
}
.sidebar-collapse .main-sidebar,
.sidebar-collapse .main-sidebar::before {
    margin-left: -132px !important;
}
body:not(.sidebar-mini-md) .content-wrapper,
body:not(.sidebar-mini-md) .main-footer,
body:not(.sidebar-mini-md) .main-header {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 132px !important;
}
@media (max-width: 991.98px) {
    body:not(.sidebar-mini-md) .content-wrapper,
    body:not(.sidebar-mini-md) .content-wrapper::before,
    body:not(.sidebar-mini-md) .main-footer,
    body:not(.sidebar-mini-md) .main-footer::before,
    body:not(.sidebar-mini-md) .main-header,
    body:not(.sidebar-mini-md) .main-header::before {
        margin-left: 0px !important;
    }
}
@media (min-width: 992px) {
    .sidebar-mini.sidebar-collapse .main-sidebar,
    .sidebar-mini.sidebar-collapse .main-sidebar::before {
        margin-left: 0;
        width: 0px !important;
    }
    .sidebar-mini.sidebar-collapse .content-wrapper,
    .sidebar-mini.sidebar-collapse .main-footer,
    .sidebar-mini.sidebar-collapse .main-header {
        margin-left: 0px !important;
    }
}
@media (min-width: 768px) {
    body:not(.sidebar-mini-md) .content-wrapper,
    body:not(.sidebar-mini-md) .main-footer,
    body:not(.sidebar-mini-md) .main-header {
        margin-left: 132px;
    }
    .sidebar-collapse .main-sidebar,
    .sidebar-collapse .main-sidebar::before {
        margin-left: 0px;
    }
}

//end margin sidebar and content

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
    background-color: #617c36 !important;
}
.nav-treeview > .nav-item > .nav-link.active,
.nav-treeview > .nav-item > .nav-link.active:hover,
.nav-treeview > .nav-item > .nav-link.active:focus {
    background-color: #617c36 !important;
}
.nav-sidebar > .nav-item.menu-open > .nav-link,
.nav-sidebar > .nav-item:hover > .nav-link,
.nav-sidebar > .nav-item > .nav-link {
    transition: all 0.3s;
}
.nav-sidebar .nav-item > .nav-link {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    gap: 4px;
    width: 7.2rem;
}

.sidebar_icon {
    font-size: 1.6rem !important;
    color: #ffffff;
}
.sidebar_title_name {
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    color: #ffffff;
}
.sidebar-mini .nav-sidebar,
.sidebar-mini .nav-sidebar > .nav-header,
.sidebar-mini .nav-sidebar .nav-link {
    white-space: normal !important;
}
.layout-fixed .brand-link {
    width: 100% !important;
}
.sidebar {
    background: #363636;
}
.sidebar_img_title {
    background: #92b558;
    display: flex;
    justify-content: center;
    padding: 0.8125rem 0.5rem;
    &_bg {
        background: #fff;
        border-radius: 50%;
        padding: 28px 2px;
    }
    &_circle {
        max-height: 33px;
        width: auto;
    }
}
// Sidebar sub-menu
.nav-sidebar > .nav-item > .nav-treeview {
    position: absolute;
    left: 124px;
    background: #363636 !important;
    top: -5px;
    border-radius: 0 5px 5px 0;
    padding: 6px;
}
.layout-fixed .main-sidebar {
    overflow: unset !important;
}
.layout-fixed.sidebar-collapse .main-sidebar {
    overflow: hidden !important;
}
.con_nav_treeview {
    position: relative;
}
.sidebar {
    overflow: unset !important;
}
.nav-sidebar {
    overflow: unset !important;
}
.sidebar-collapse {
    .sidebar {
        overflow: hidden !important;
    }
    .nav-sidebar {
        overflow: hidden !important;
    }
}

.nav-sidebar > .nav-item.menu-open > .nav-link,
.nav-sidebar > .nav-item:hover > .nav-link,
.nav-sidebar > .nav-item > .nav-link:focus {
    background-color: rgb(97 124 54 / 45%) !important;
}
// navbar
.flex_between {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    li {
        list-style-type: none;
    }
}

// Login
.con_login {
    margin: 4% auto;
    width: 503px;
    height: auto;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1% 1% 0% 1%;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 38px;
    @media screen and (max-width: 534px) {
        width: 95%;
    }
    &_header {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    &_body {
        &_message {
            text-align: center;
            color: #626262;
            letter-spacing: 1px;
            font-size: 20px;
            margin-bottom: 2rem !important;
            @media screen and (max-width: 440px) {
                font-size: 18px;
            }
            @media screen and (max-width: 350px) {
                font-size: 16px;
            }
        }
        &_input {
            padding: 0 26px;
            display: flex;
            flex-direction: column;
            gap: 6px;
            .form-control {
                border-radius: 10px;
                height: 50px;
                background-color: #f3f6f9 !important;
            }
            .form-check {
                padding-left: 0 !important;
                .checkbox-round {
                    width: 1.3em;
                    height: 1.3em;
                    background-color: #f3f6f9;
                    border-radius: 5px;
                    vertical-align: middle;
                    border: 1px solid #ddd;
                    appearance: none;
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                }
                .checkbox-round:checked {
                    background-color: #7ead30;
                }
            }
        }
        .con_btn_login {
            margin-top: 16px;
            padding: 0 12%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            &_bg {
                background: #7ead30;
                border-radius: 20px;
                font-size: 25px;
                border: none;
                height: 50px;
                text-align: center;
                color: #ffffff;
                transition: all 0.3s;
                &:hover {
                    background: #588017;
                    transform: scale(1.1);
                }
            }
            &_guest {
                font-size: 20px;
                text-align: center;
                color: #555555;
                cursor: pointer;
                transition: all 0.3s;
                &:hover {
                    color: #353535;
                    transform: scale(1.1);
                }
            }
        }
    }
    &_footer {
        border-top: 2px solid #7ead30;
        padding: 32px 0px;
        &_message {
            text-align: center;
            font-size: 20px;
            line-height: 23px;
            color: #555555;
            margin-bottom: 0px !important;
            @media screen and (max-width: 440px) {
                font-size: 16px;
            }
        }
    }

  /* The "Forgot password" text */
  span.psw {
    float: right;
    padding-top: 2px;
  }
  .textforgotpassword{
    font-size: 12px;
    color: #555555;
    text-align: center;
    cursor: pointer;
    // transition: all 0.3s;
    // &:hover {
    //     color: #353535;
    //     transform: scale(1.1);
    // }
  }
}
/* Full-width input fields */
.inputs[type=text] {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
    border-radius: 10px;
    background: #f1f1f1;
  }

  .inputs[type=text]:focus {
    background-color: #ddd;
    outline: none;
  }


  .btn:hover {
    opacity:1;
  }

  .Borderforget {
    border: 1px solid #f1f1f1;
    padding: 10px;
    width: 100%;
    text-align: center;
    margin-bottom: 1px;
  }
  .textforget {
    color: #854d3d;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0px !important;
  }
    .textforget:hover {
        color: #7ead30;
        text-decoration: underline;
    }
